// @flow

import styled, { css, keyframes } from "styled-components";
import { rgba } from "polished";

import Logo from "../../_static/Logos";
import Button from "../../_atom/Buttons/button";
import DefaultButton from "../../_atom/Buttons/default";
import Icon from "../../_static/Icons";
import MessageBox from "../../_molecule/MessageBox";

import buildColor from "../../_static/ColorPalette";
import {
  defaultSize,
  fontBold,
  fontNormal,
  fontMedium
} from "../../_static/Typography";

export const Page = styled.div`
  position: relative;
  padding: 24px 8px 0;
  background-color: ${buildColor("blue", "700")};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
`;

export const CloseBtn = styled(DefaultButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 16px;
  height: 16px;
  background: none;
`;

export const LogoUnshrink = styled(Logo)`
  flex-shrink: 0;
`;

export const IconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) =>
    props.verticalAlign ? "center" : "flex-start"};
  flex-grow: 1;
  margin: 24px 0 16px;
  flex-shrink: 0;
  max-width: 414px;
`;

export const CircleContainer = styled.div`
  min-height: 104px;
  max-height: 112px;
  flex-shrink: 1;
  flex-grow: 1;
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: ${rgba(buildColor("black", "100"), 0.15)};

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const RotateAnimation = keyframes`
  0% { 
    transform: rotate(0deg);    
  } 

  75%, 100% {
    transform: rotate(360deg);    
  }
`;

export const MainIcon = styled(Icon)`
  ${(props) =>
    props.loading
      ? css`
          animation: ${RotateAnimation} 750ms infinite ease-in-out running;
        `
      : css`
          margin-bottom: ${props.hasBottomMargin && "8px"};
        `};
`;

export const PreTitle = styled.h2`
  font-size: 18px;
  color: ${buildColor("white", "100")};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: ${buildColor("white", "100")};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const Description = styled.p`
  font-size: ${defaultSize};
  color: ${buildColor("blue", "300")};
  text-align: center;
  line-height: 18px;
  white-space: pre-line;

  & > * > em {
    font-style: normal;
    color: ${buildColor("white", "100")};
  }

  & > * > a {
    text-decoration: none;
    color: ${buildColor("blue_accent", "500")};
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const MsgAndInfoBoxContainer = styled.div`
  width: 100%;
  max-width: 414px;
`;

export const InfoBox = styled.div`
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: ${buildColor("white", "100")};

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const InfoMessage = styled(MessageBox)`
  border-radius: 2px;
`;

export const InstructionsTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 4px;

  & :not(:first-child) {
    margin-top: 8px;
  }
`;

export const Instructions = styled.ol`
  font-size: 12px;
  text-align: left;
  color: ${buildColor("grey", "900")};
  counter-reset: item;
`;

export const SingleInstruction = styled.p`
  font-size: 12px;
  text-align: left;
  color: ${buildColor("grey", "900")};
`;

export const Instruction = styled.li`
  list-style-type: none;
  counter-increment: item;

  & ::before {
    content: counter(item) " - ";
    font-weight: bold;
  }

  & :not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const BtnsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Btn = styled(Button)`
  flex-grow: 1;
  flex-basis: ${(props) => props.flexBasis};

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const BtnIcon = styled(Icon)`
  margin-right: 8px;
`;

export const CustomerServicesInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const CustomerServicesIcon = styled(Icon)`
  margin: 0 4px 0 16px;
`;

export const CustomerServicesText = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  color: ${buildColor("grey", "900")};
  text-transform: uppercase;
`;

export const CustomerServicesUrl = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: ${defaultSize};
  line-height: 24px;
  font-weight: bold;
  color: ${buildColor("blue_accent", "500")};
`;

export const BlockedIconTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const IconsContainer = styled.div`
  position: relative;
`;

export const SecundaryIcon = styled(Icon)``;

export const BlockedTitle = styled.h1`
  font-family: ${fontBold};
  text-align: center;
  color: ${buildColor("white", "100")};
  font-size: 16px;
  line-height: 22px;
  margin-top: 38px;
`;

export const BlockedDescription = styled.p`
  color: ${buildColor("blue", "100")};
  margin-top: 10px;
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  width: 37px;
  height: 37px;
  position: absolute;
  top: -10px;
  right: -10px;
`;

export const BlockedCountryBtn = styled(Button)`
  width: 100%;
  height: 44px;
  background-color: ${buildColor("white", "10")};
  margin-bottom: 12px;
  text-transform: none;
  font-size: 14px;
  font-family: ${fontMedium};
  color: ${buildColor("blue", "000")};

  &:hover {
    background-color: ${buildColor("white", "20")};
    color: ${buildColor("white", "100")};
  }

  &:focus {
    background-color: ${buildColor("white", "30")};
    color: ${buildColor("white", "100")};
  }
`;
