// @flow
import React, { Component, Fragment } from "react";
import { get, isEqual } from "lodash";

import Icon from "../../_static/Icons";
import SpinningWheel from "../../_atom/SpinningWheel";
import * as allIcons from "../../_static/Icons/icons";

import buildColor from "../../_static/ColorPalette";
import CountryIcon from "../../_static/SvgImages/CountryIcon";
import EmailIcon from "../../_static/SvgImages/emailTvg";

import type { Props } from "./types";
import {
  Page,
  CloseBtn,
  LogoUnshrink,
  IconTextContainer,
  CircleContainer,
  IconCircle,
  MainIcon,
  PreTitle,
  Title,
  Description,
  MsgAndInfoBoxContainer,
  InfoBox,
  InfoMessage,
  InstructionsTitle,
  Instructions,
  SingleInstruction,
  Instruction,
  BtnsContainer,
  Btn,
  BtnIcon,
  CustomerServicesInfoContainer,
  CustomerServicesIcon,
  CustomerServicesText,
  CustomerServicesUrl,
  BlockedIconTextContainer,
  IconsContainer,
  SecundaryIcon,
  BlockedTitle,
  BlockedDescription,
  BlockedCountryBtn,
  IconWrapper
} from "./styled-components";

const ICON_FALLBACK = allIcons.exclamation;

const iconMap = {
  error: allIcons.errorOutline,
  location: allIcons.locationPin,
  warning: allIcons.warningOutline,
  loading: allIcons.refresh,
  overload: allIcons.overload,
  settings: allIcons.settings,
  refresh: allIcons.refresh,
  wifi: allIcons.wifi,
  timer: allIcons.timer,
  calendar: allIcons.calendar,
  lock: allIcons.lock,
  exclamationMarkYellow: allIcons.exclamationMarkYellow
};

const genQALabel = (value: string = "") =>
  value.replace(/_/g, "-").toLowerCase();

export default class ErrorPage extends Component<Props> {
  static defaultProps = {
    brand: "tvg",
    icon: "warning",
    title: "",
    showCustomerServicesUrl: false,
    isApp: false,
    infoType: "info",
    isAndroidBlocked: false,
    isLoading: false,
    androidBlockType: "country"
  };

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  getAndroidSVGicon = () => {
    switch (this.props.androidBlockType) {
      case "country":
        return (
          <React.Fragment>
            <CountryIcon />
            <IconWrapper>
              <SecundaryIcon
                size={37}
                icon={iconMap.exclamationMarkYellow || ICON_FALLBACK}
              />
            </IconWrapper>
          </React.Fragment>
        );
      case "state":
        return <EmailIcon />;

      default:
        return <CountryIcon />;
    }
  };

  renderButtons() {
    return (
      this.props.buttons &&
      this.props.buttons.map((buttonLine, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <BtnsContainer key={index} data-qa-label="btns-container">
          {buttonLine.map((button) => (
            <Btn
              key={button.text}
              onClick={button.onClick}
              flexBasis={button.flexBasis}
              type={button.type}
              size="big"
              isDisabled={button.isDisabled}
              qaLabel={`btn-${genQALabel(button.text)}`}
            >
              {button.icon && (
                <BtnIcon
                  icon={iconMap[button.icon] || ICON_FALLBACK}
                  size={16}
                  color={buildColor("white", "100")}
                  qaLabel={`btn-${genQALabel(button.text)}-icon`}
                />
              )}
              {button.text}
            </Btn>
          ))}
        </BtnsContainer>
      ))
    );
  }

  renderInstructions() {
    if (this.props.instructions) {
      return (
        <div>
          {this.props.instructions.map((instructionsObj) => (
            <Fragment key={`instructions-${instructionsObj.title || ""}`}>
              {instructionsObj.title && (
                <InstructionsTitle>{instructionsObj.title}</InstructionsTitle>
              )}
              {instructionsObj.instructions.length === 1 ? (
                <SingleInstruction>
                  {instructionsObj.instructions[0]}
                </SingleInstruction>
              ) : (
                <Instructions>
                  {instructionsObj.instructions.map((instruction) => (
                    <Instruction key={instruction}>{instruction}</Instruction>
                  ))}
                </Instructions>
              )}
            </Fragment>
          ))}
        </div>
      );
    }
    return null;
  }

  renderCustomerServicesIcon = () => (
    <CustomerServicesIcon
      icon={allIcons.chat}
      size={16}
      color={buildColor("blue_accent", "500")}
    />
  );

  renderInfoBox() {
    if (
      this.props.infoText ||
      this.props.instructions ||
      this.props.buttons ||
      (this.props.showCustomerServicesUrl && this.props.customerServicesUrl)
    ) {
      return (
        <InfoBox>
          {this.props.infoText && (
            <InfoMessage
              message={this.props.infoText}
              type={this.props.infoType}
            />
          )}
          {this.renderInstructions()}
          {this.props.buttons && this.renderButtons()}
          {this.props.showCustomerServicesUrl &&
            this.props.customerServicesUrl && (
              <CustomerServicesInfoContainer>
                <CustomerServicesText>Need help?</CustomerServicesText>
                {this.props.isApp ? (
                  <CustomerServicesUrl
                    to={this.props.customerServicesUrl}
                    onClick={this.props.iOSMessageUsCallback}
                  >
                    {this.renderCustomerServicesIcon()}
                    Message us
                  </CustomerServicesUrl>
                ) : (
                  <CustomerServicesUrl
                    target="_blank"
                    href={this.props.customerServicesUrl}
                  >
                    {this.renderCustomerServicesIcon()}
                    Message us
                  </CustomerServicesUrl>
                )}
              </CustomerServicesInfoContainer>
            )}
        </InfoBox>
      );
    }
    return null;
  }

  renderIconTextContainer = () => (
    <IconTextContainer verticalAlign={this.props.icon === "loading"}>
      <CircleContainer data-qa-label="circle-container">
        <IconCircle data-qa-label="icon-circle">
          <MainIcon
            hasBottomMargin={this.props.icon === "warning"}
            icon={iconMap[this.props.icon] || ICON_FALLBACK}
            size={40}
            color={buildColor("white", "100")}
            loading={this.props.icon === "loading"}
            qaLabel="icon-circle-main"
          />
        </IconCircle>
      </CircleContainer>
      {this.props.preTitle && (
        <PreTitle
          data-qa-label={`pre-title-${genQALabel(this.props.preTitle)}`}
        >
          {this.props.preTitle}
        </PreTitle>
      )}
      <Title data-qa-label={`title-${genQALabel(this.props.title)}`}>
        {this.props.title}
      </Title>
      {this.props.description && (
        <Description
          data-qa-label={`description-${genQALabel(this.props.title)}`}
        >
          {this.props.description}
        </Description>
      )}
    </IconTextContainer>
  );

  renderInfoAndroidBlocked = () => {
    return (
      <BlockedIconTextContainer>
        <IconsContainer data-qa-label="icon-circle">
          {this.getAndroidSVGicon()}
        </IconsContainer>
        <BlockedTitle data-qa-label={`title-${genQALabel(this.props.title)}`}>
          {this.props.title}
        </BlockedTitle>
        {this.props.description && (
          <BlockedDescription
            dangerouslySetInnerHTML={{ __html: this.props.description }}
            data-qa-label={`description-${genQALabel(this.props.title)}`}
          />
        )}
      </BlockedIconTextContainer>
    );
  };

  renderAndroidBlockedButton = () => {
    const button = get(this.props, "buttons[0][0]");
    return (
      button && (
        <BlockedCountryBtn
          key={button.text}
          onClick={button.onClick}
          isDisabled={button.isDisabled}
          qaLabel={`btn-${genQALabel(button.text)}`}
        >
          {this.props.isLoading ? (
            <SpinningWheel />
          ) : (
            <Fragment>
              {button.icon && (
                <BtnIcon
                  icon={iconMap[button.icon] || ICON_FALLBACK}
                  size={16}
                  color={buildColor("blue", "000")}
                  qaLabel={`btn-${genQALabel(button.text)}-icon`}
                />
              )}
              {button.text}
            </Fragment>
          )}
        </BlockedCountryBtn>
      )
    );
  };

  render() {
    return (
      <Page className={this.props.className}>
        {this.props.onClose && (
          <CloseBtn onClick={this.props.onClose}>
            <Icon
              icon={allIcons.close}
              size={16}
              color={buildColor("white", "100")}
            />
          </CloseBtn>
        )}
        <LogoUnshrink brand={this.props.brand} height={24} />
        {this.props.isAndroidBlocked
          ? this.renderInfoAndroidBlocked()
          : this.renderIconTextContainer()}
        <MsgAndInfoBoxContainer
          data-qa-label={`mg-and-info-box-container-${genQALabel(
            this.props.preInfoText
          )}`}
        >
          {this.props.preInfoText && (
            <Description
              data-qa-label={`mg-and-info-box-description-${genQALabel(
                this.props.preInfoText
              )}`}
            >
              {this.props.preInfoText}
            </Description>
          )}
          {this.props.isAndroidBlocked
            ? this.renderAndroidBlockedButton()
            : this.renderInfoBox()}
        </MsgAndInfoBoxContainer>
      </Page>
    );
  }
}
